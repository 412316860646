/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        // AOS
        AOS.init();
        
        // Bootstrap-select
        
        $.fn.selectpicker.Constructor.BootstrapVersion = '4';
        
        $('select').selectpicker();
        
        // Hamburger
        
        var $hamburger = $(".hamburger");
        
			  $hamburger.on("click", function(e) {
			    $hamburger.toggleClass("is-active");
			    $(".djw-menu").toggleClass("djw-menu-active");
					$("body").toggleClass("modal-open");
			  });
        
        // Headroom

		    var header = document.querySelector("header");
		
		    var headroom = new Headroom(header, {
	        tolerance: 1,
	        offset : 100,
		    });
		    
		    headroom.init();
		    
		    $("p a").addClass("hvr-underline-from-left");
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
				
				$('.djw-home-cover-manufacturers-carousel').slick({
					arrows: false,
					autoplay: true,
				  infinite: true,
				  slidesToShow: 5,
				  slidesToScroll: 1,
				  speed: 1000,
				  responsive: [
				    {
				      breakpoint: 992,
				      settings: {
				        slidesToShow: 4,
				      }
				    },
				    {
				      breakpoint: 768,
				      settings: {
				        slidesToShow: 3,
				      }
				    }
				  ]
				});        
        
				$('.djw-home-vehicles-carousel').slick({
					arrows: false,
					autoplay: true,
				  infinite: true,
				  slidesToShow: 4,
				  slidesToScroll: 1,
				  responsive: [
				    {
				      breakpoint: 1440,
				      settings: {
				        slidesToShow: 3,
				      }
				    },
				    {
				      breakpoint: 992,
				      settings: {
				        slidesToShow: 2,
				      }
				    },
				    {
				      breakpoint: 768,
				      settings: {
				        slidesToShow: 1,
				      }
				    }
				  ]
				});
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    
    'single_vehicles': {
	    init: function() {
				var scroll = new SmoothScroll('a[href*="#"]', {
					speed: 1500,
					updateURL: false,
				});
			},
			finalize: function() {
				$('.djw-single-vehicle-carousel').slick({
					fade: true,
				  nextArrow: '<button type="button" class="slick-next"><i class="far fa-angle-right"></i></button>',
				  prevArrow: '<button type="button" class="slick-prev"><i class="far fa-angle-left"></i></button>',
				});
			}
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
